.card {
  background-color: white;
  border-radius: 12px;
  flex-grow: 1;
  margin: 5px;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  box-shadow: 2px 1px 5px 0px rgba(150, 150, 150, 0.15);
  flex-direction: column;
  padding: 15px;
  width: 25%;
}

.card:hover {
  transform: scale(1.05);
}

.card-text {
  text-align: center;
  font-weight: bold;
}

.shape {
  width: 50px;
  height: 50px;
  margin: 15px;
}
