.download {
  max-width: 800px;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.43);
  text-decoration: none;
  color: black;
  position: relative;
  padding: 5px 15px;
  padding-right: 0px;
}

.download-left {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 0 12px 12px 0;
  background: -webkit-linear-gradient(-45deg, #ff512f, #dd2476);
}

.icon-down {
  width: 24px;
  height: 24px;
}

.download-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 10px;
}

.download-title {
  font-weight: bold;
  font-size: 18px;
}
.download-subtitle {
  padding-top: 5px;
}
