.cooperation {
  background-color: white;
}

.cooperation-title {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 72px;
  background: -webkit-linear-gradient(-45deg, #ff512f, #dd2476);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 600px) {
  .cooperation-title {
    font-size: 48px;
    text-align: center;
  }
}

.cooperation-item {
  max-width: 350px;
  width: 100%;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cooperation-bubble {
  background: -webkit-linear-gradient(-45deg, #ff512f, #dd2476);
  width: 48px;
  height: 48px;
  padding: 30px;
  border-radius: 50%;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  font-weight: bold;
}

.cooperation-logo {
  height: 60px;
  margin-bottom: 15px;
}

.cooperation-logo img{
  width: 100%;
  height: 100%;
}

.cooperation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.cooperation-text {
  text-align: center;
}
