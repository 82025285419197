.register {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0px;
}

.pill {
  background: -webkit-linear-gradient(-45deg, #ff512f, #dd2476);
}

.register .countdown {
  font-weight: bold;
  font-size: 36px;
  padding: 40px;
}

.register .input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 12px;
}
