.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

@media only screen and (max-width: 600px) {
  .center-container {
    padding: 0px;
  }
}

.center {
  max-width: 800px;
  width: 100%;
}
