body {
  padding: 0;
  margin: 0;
  width: 100vw;
  min-height: 200vh;
  height: 100%;
  background: #fa5332; /* fallback for old browsers */

  font-family: "helvetica", sans-serif;
  flex-direction: column;
  background: linear-gradient(-45deg, #ff512f, #dd246b);
  background-size: 400% 400%;

  -webkit-animation: gradient 0s ease infinite;
  -moz-animation: gradient 0s ease infinite;
  animation: gradient 15s ease infinite;
}

.cookie-button {
  width: 100%;
  display: flex;
}

.headline {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headline-content img {
  width: 200px;
}

@media only screen and (max-width: 600px) {
  .headline-content img {
    width: 64px;
  }
}

.download-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.headline-content {
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.content-center {
  max-width: 800px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .headline-content {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .headline {
    padding: 20px;
  }
}

h1 {
  color: white;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
}

h2 {
  color: white;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}

h3 {
  color: white;
  text-align: center;
  font-weight: normal;
  font-size: 28px;
  margin-top: 10px;
}

h4 {
  color: white;
  text-align: center;
  font-size: 28px;
  margin-top: 10px;
}
h5 {
  color: white;
  text-align: center;
  font-size: 16px;
}

p {
  color: white;
}

.background {
  position: fixed;
  top: -200px;
  left: -500px;
  right: 0;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.content {
}

.icon {
  opacity: 0.05;
  width: 130%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.space-between-items {
  padding-bottom: 50px;
}

.link {

  color: green;
}

.a {
  color: green;
}

.linkdiv {
  font-size: x-small;
  font-weight: lighter;
}
